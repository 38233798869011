<template>
    <div>
        <router-link class="card text-dark text-decoration-none rounded-xl shadow border-0"
                     v-bind:class="{ 'is-selected': isSelected }"
                     v-bind:to="`/organizers/${ id }`">
            <div class="card-body p-2">
                <div class="d-flex">
                    <div class="col-auto d-flex align-items-center">
                        <b-avatar class="shadow-sm border-0"
                                  size="lg">
                            <img class="w-100 h-100"
                                 style="object-fit: cover"
                                 v-if="avatar"
                                 v-bind:alt="name"
                                 v-bind:src="avatar">
                            <h4 class="mb-0"
                                v-else>
                                <font-awesome-icon v-bind:icon="['fad', 'user-crown']"></font-awesome-icon>
                            </h4>
                        </b-avatar>
                    </div>
                    <div class="flex-grow-1 d-flex flex-column justify-content-center">
                        <h5 class="font-weight-bolder mb-1">
                            {{ name }}
                        </h5>
                        <p class="mb-0">
                            +{{ countryCode }} {{ phone }}
                        </p>
                    </div>
                    <div class="col-auto d-flex">
                        <div class="col-6 col-md-4 col-lg mb-2 mb-md-0">
                            <h2 class="text-center font-weight-bold text-nowrap mb-0">
                                {{ activeRegions[0].name }} <small v-if="activeRegions.length > 1">and {{ activeRegions.length -1 }} more</small>
                            </h2>
                            <p class="text-center text-nowrap mb-1">
                                🌏 Regions
                            </p>
                        </div>
                        <div class="col-6 col-md-4 col-lg mb-2 mb-md-0">
                            <h2 class="text-center font-weight-bold text-nowrap mb-0">
                                <strong>{{ rating }}</strong><small style="font-size: 50%; ">/ 5.0</small>
                            </h2>
                            <p class="text-center text-nowrap mb-1">
                                ⭐ Rating
                            </p>
                        </div>
                        <div class="col-6 col-md-4 col-lg mb-2 mb-md-0">
                            <h2 class="text-center font-weight-bold mb-0">
                                {{ count }}
                            </h2>
                            <p class="text-center text-nowrap mb-1">
                                👍 Count
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </router-link>
    </div>
</template>

<script>
import { apiUrl, apiVersion2 } from "@/config";
import { BAvatar, } from "bootstrap-vue";
export default {
    name: "OrganizerCard",
    components: {
        BAvatar,
    },
    props: {
        organizer: {
            type: Object,
        },
    },
    data () {
        return {

        };
    },
    computed: {
        id () {
            return this.organizer?._id ?? "";
        },
        isSelected () {
            return this.id === this.$route.params.organizerId;
        },
        name () {
            return this.organizer?.profile?.nickname || this.organizer?.name || "";
        },
        countryCode () {
            return this.organizer?.countryCode ?? "";
        },
        phone () {
            return this.organizer?.phone ?? "";
        },
        avatar () {
            return this.organizer?.avatar ?
                `${ apiUrl }/${ apiVersion2 }/user/${ this.id }/avatar` :
                "";
        },
        rating () {
            return parseFloat(this.organizer?.profile?.organizerOverall) ?
                parseFloat(this.organizer?.profile?.organizerOverall).toFixed(1) :
                "NA";
        },
        count () {
            return this.organizer?.profile?.organizerResponseCount ?
                parseFloat(this.organizer?.profile?.organizerResponseCount).toFixed(0) :
                "NA";
        },
        activeRegionCodes () {
            return this.organizer?.activeRegions ?? ["HKG"];
        },
        activeRegions () {
            return this.activeRegionCodes.map(code => this.$store.getters["region/itemByCode"](code));
        },
    },
    methods: {

    },
}
</script>

<style lang="scss" scoped>
.card {
    transition:
        transform 150ms ease-in-out,
        box-shadow 200ms ease-in-out;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.08%);

    &:hover,
    &:active,
    &.is-selected {
        transform: scale(1.01);
        box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    }
}
</style>
